<template>
  <div class="view-patient">
    <v-container v-if="customer" class="ml-4">
      <v-row>
        <v-col cols="9">
          <card><!--:isDisabled="!customer.active"-->
            <template v-slot:body>
              <v-row>
                <v-col cols="12" style="padding: 0">
                  <v-container fluid>
                    <v-row class="mt-0 align-center" style="column-gap: 0.5rem;">
                      <v-col class="px-0 py-0">
                        <div class="d-flex align-center" style="column-gap: 0.5rem">
                          <div class="view-patient__image">
                            <v-avatar size="70" v-if="customer.logoSmall">
                              <v-img :src="customer.logoSmall" alt="profile-avatar" />
                            </v-avatar>
                            <div class="status">
                              <img v-if="customer.subscription.trial" :src="getImgWithExt(['icons', 'trial', 'svg'])" width="30px" height="30px">
                              <v-icon v-else style="bottom: 10px" color="#008C89">mdi-check-circle</v-icon>
                            </div>
                          </div>
                          <div class="view-patient__fullname py-2">
                            <div>{{customer.practiceName || '-'}}</div>
                            <div style="font-size: 14px; font-weight: 400">{{customer.practiceTitle || '-'}}</div>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="px-0 py-0">
                        <div class="label">Street address</div>
                        <div class="text" style="min-width: 210px">{{customer.addressLine1 || '-'}}</div>
                      </v-col>
                      <v-col class="px-0 py-0">
                        <div class="label">City</div>
                        <div class="text">{{customer.city || '-'}}</div>
                      </v-col>
                      <v-col class="px-0 py-0">
                        <div class="label">Zip Code</div>
                        <div class="text">{{customer.zip || '-'}}</div>
                      </v-col>
                      <v-col class="px-0 py-0">
                        <div class="label">State</div>
                        <div class="text">{{customer.state || '-'}}</div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col class="my-2"><v-divider /></v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-container fluid>
                    <v-row class="mt-0 align-self-start justify-space-around" style="column-gap: 0.2rem;">
                      <v-col cols="3" class="px-0 py-0">
                        <div class="view-patient__joined">
                          Joined {{$moment(customer.createdOn).format('MM/DD/YYYY')}}
                        </div>
                        <v-row class="mt-0">
                          <v-col>
                            <v-icon>mdi-phone-outline</v-icon>
                            {{customer.phoneNumber || '-'}}
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <v-icon>mdi-email-outline</v-icon>
                            {{customer.email || '-'}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4" class="px-0 py-0">
                        <div>
                          <v-col style="text-align: end" class="pr-0">
                            <v-row style="flex-wrap: nowrap;column-gap: 5px;">
                              <div class="label" style="width: 100px">Subscription:</div>
                              <div style="display: flex;text-align: left;">{{customer.subscription.productName || '-'}}</div>
                            </v-row>
                            <v-row style="flex-wrap: nowrap;column-gap: 5px;">
                              <div class="label" style="width: 100px">Patients:</div>
                              <div style="display: flex;text-align: left;">{{customer.patientsCount || '-'}}</div>
                            </v-row>
                            <v-row style="flex-wrap: nowrap;column-gap: 5px;">
                              <div class="label" style="width: 100px">Practitioners:</div>
                              <div style="display: flex;text-align: left;">{{customer.doctorsCount || '-'}}</div>
                            </v-row>
                            <v-row v-if="customer.subscription?.features?.$values" style="flex-wrap: nowrap;column-gap: 5px;">
                              <div class="label" style="width: 100px">Feature:</div>
                              <div style="display: flex;text-align: left;">{{customer.subscription?.features?.$values?.toString() || '-'}}</div>
                            </v-row>
                          </v-col>
<!--                          <div>
                            <v-col class="px-0 py-0">
                              <span class="label">Migration: </span>
                              <span>{{customer.migrationStatus || '-'}}</span>
                            </v-col>
                            <v-col class="px-0 py-0">
                              <span class="label">Subscription: </span>
                              <span>{{customer.subscription.productName || '-'}}</span>
                            </v-col>
                          </div>
                          <div>
                            <v-col class="px-0 py-0">
                              <span class="label">Patients: </span>
                              <span>{{customer.patientsCount || '-'}}</span>
                            </v-col>
                            <v-col class="px-0 py-0">
                              <span class="label">Doctors: </span>
                              <span>{{customer.doctorsCount || '-'}}</span>
                            </v-col>
                          </div>
                          <div>
                            <v-icon color="#008C89" v-if="!customer.isLegacy">mdi-check-circle</v-icon>
                          </div>-->
                        </div>
                      </v-col>
                      <v-col cols="4" class="px-0 py-0">
                        <v-row style="column-gap: 0.5rem;">
                          <v-col style="text-align: end" class="pr-0">
                            <v-switch class="py-0 my-0 custom-switch-label" color="#008C89" v-model="customer.qrLeadFeature" inset @change="enableLeadsFeature" />
                            <div class="label">Legacy:</div>
                            <div class="label" v-if="customer.isLegacy">Migration:</div>
                          </v-col>
                          <v-col style="text-align: left" class="pl-0">
                            <div class="label" style="width: max-content;">
                              Enable Leads Feature
                            </div>
                            <div class="label">
                              <v-icon v-if="customer.isLegacy" color="#008C89">mdi-check-circle</v-icon>
                              <v-icon v-else color="#CF4655">mdi-close-circle</v-icon>
                            </div>
                            <div v-if="customer.isLegacy">{{customer.migrationStatus || '-'}}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="justify-end"><!--v-if="customer.isLegacy"-->
                      <div>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="#008C89"
                              style="width: 178px"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="font-weight-bold" style="color: white">Actions</span>
                              <v-icon style="color: white">mdi-arrow-down-thin</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in actionList"
                              :key="index"
                            >
                              <v-list-item-title style="cursor:pointer;" @click="runMethod(item.method)">{{ item.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col class="my-2"><v-divider /></v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col cols="10">
                        <div style="font-weight: 600;font-size: 14px;line-height: 16px;color: #718096;">Brand Vendors</div>
                        <v-row class="mt-1">
                          <v-col cols="6">
                            <v-switch class="py-0 my-0" color="#008C89" inset label="Standard Process / Mediherb" v-model="brand" value="SPMH" />
                            <v-switch class="py-0 my-0" color="#008C89" inset label="Biotics Research" v-model="brand" value="BR" />
                          </v-col>
                          <v-col cols="6">
                            <v-switch class="py-0 my-0" color="#008C89" inset label="NutriWest" v-model="brand" value="NW"/>
                            <v-switch class="py-0 my-0" color="#008C89" inset label="Food Research" v-model="brand" value="FR" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
<!--              <v-row>
                <v-col>
                  <v-expansion-panels tile flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div class="d-flex justify-end label">Full information</div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>
                          <v-col class="px-0 py-0">
                            <div class="label">Street address</div>
                            <div class="text">{{customer.line1}}</div>
                          </v-col>
                          <v-col class="px-0 py-0">
                            <div class="label">City</div>
                            <div class="text">{{customer.city}}</div>
                          </v-col>
                          <v-col class="px-0 py-0">
                            <div class="label">Zip Code</div>
                            <div class="text">{{customer.zip}}</div>
                          </v-col>
                          <v-col class="px-0 py-0">
                            <div class="label">State</div>
                            <div class="text">{{customer.state}}</div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>-->
            </template>
          </card>
          <card class="mt-4"><!--:isDisabled="!customer.active"-->
            <template v-slot:body>
              <Tabs :items="tabs"
                    @update:tab="tab"
                    :tab-index="tabIndex"
                    left
                    :withoutPadding="tabs[componentInd].component === 'chat' || tabs[componentInd].component === 'payments' || tabs[componentInd].component === 'events' ? 'chat-without-padding' : ''"
                    :id="tabs[componentInd].component === 'chat' ? 'chat' : undefined"><!--:isDisabled="!customer.active"-->
                <template v-slot:sliderColor>
                  <v-tabs-slider color="#008C89"></v-tabs-slider>
                </template>
                <template v-slot:[`${tabs[componentInd].component}`]>
                  <component :patient="customer" :is="tabs[componentInd].component" :styles="{ 'min-height': 'calc(100vh - 307px)' }"></component>
                </template>
              </Tabs>
              <!--              <div class="visits-list">
                              <v-timeline dense>
                                <v-timeline-item color="success" v-for="(visit, key) in customer.visits.$values" :key="key">
                                  <card>
                                    <template v-slot:body>
                                      <v-row>
                                        <v-col class="px-2 py-2" cols="2">
                                          <div class="visits-list__date">{{$moment(visit.date).format('MMM DD, YYYY')}}</div>
                                          <div class="visits-list__doctor">Visit to Dr. {{visit.doctorFullName}}</div>
                                        </v-col>
                                        <v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col>
                                        <v-col class="px-2 py-2" cols="6">
                                          <div class="label">Notes</div>
                                          <div class="visits-list__note">{{visit.notes}}</div>
                                        </v-col>
                                        <v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col>
                                        <v-col class="px-2 py-2" cols="2">
                                          <div class="visits-list__navs d-flex align-center">
                                            <v-icon color="#4FAA6E" class="mr-2 file" @click.native="navToSurvey(visit.id)">mdi-file-outline</v-icon>
                                            <v-icon color="#668BFF" class="briefcase" @click.native="navToResult(visit.id)">mdi-briefcase-plus</v-icon>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </card>
                                </v-timeline-item>
                              </v-timeline>
                            </div>-->
            </template>
          </card>
        </v-col>
        <v-col cols="3">
          <v-container>
            <v-row>
              <v-col class="py-0 mb-4">
                <card><!--:isDisabled="!customer.active"-->
                  <template v-slot:body>
                    <div style="position: relative">
                      <div>
                        <div class="doctor__image d-flex justify-center">
                          <v-avatar size="70" v-if="customer.doctor.logoSmall">
                            <v-img :src="customer.doctor.logoSmall" alt="profile-avatar" />
                          </v-avatar>
                        </div>
                        <div class="doctor__full-name">
                          {{customer.doctor.name}}
                        </div>
                        <div class="doctor__info">
                          <v-row class="mt-0">
                            <v-col>
                              <v-icon>mdi-phone-outline</v-icon>
                              {{customer.doctor.phoneNumber || '-'}}
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <v-col>
                              <v-icon>mdi-email-outline</v-icon>
                              {{customer.doctor.email || '-'}}
                            </v-col>
                          </v-row>
                        </div>
                        <div class="doctor__link">
                          <v-row class="mt-0">
                            <v-col>
                              <a style="text-decoration: none; color: #008c89" :href="`${customer.domain}/redirect/${userProfile.userId}/${customer.token}`" target="_blank">Login to Doctor Portal</a>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                  </template>
                </card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <card><!--:isDisabled="!customer.active"-->
                  <template v-slot:body>
                    <div class="text-h6 font-weight-bold black--text mb-4" style="position: relative">
                      Usage Data
                      <div class="last-visit-date">{{customer.lastVisitDate ? $moment(customer.lastVisitDate).format('LL') : ''}}</div>
                    </div>
                    <div>
                      <div v-if="customer.customProductsCount">Manual Products: {{customer.customProductsCount}}</div>
                      <div v-if="customer.customProtocolsCount">Manual Protocols: {{customer.customProtocolsCount}}</div>
                      <div v-if="customer.customSupplementsCount">Manual Supplements: {{customer.customSupplementsCount}}</div>
                      <div v-if="customer.patientsCount">Patients (non-portal): {{customer.patientsCount}}</div>
                      <div v-if="customer.portalPatientsCount">Patients (portal): {{customer.portalPatientsCount}}</div>
                      <div v-if="customer.surveysCount">Surveys: {{customer.surveysCount}}</div>
                      <div v-if="customer.portalSurveysCount">Portal Surveys: {{customer.portalSurveysCount}}</div>
                      <div v-if="customer.portalSurveysCount">Blood Panels: {{customer.portalSurveysCount}}</div>
                      <div v-if="customer.doctorsCount">Practitioners: {{customer.doctorsCount}}</div>
                      <div v-if="customer.protocolsAddedCount">Protocols Added: {{customer.protocolsAddedCount}}</div>
                      <div v-if="customer.top20AddedProtocolNames && customer.top20AddedProtocolNames.left > 0">Top Added Protocols: <br>
                        <span v-for="protocolName in customer.top20AddedProtocolNames" :key="protocolName">
                          {{protocolName}}<br>
                        </span>
                      </div>
                    </div>
                  </template>
                </card>
              </v-col>
            </v-row>
<!--            <v-row v-if="customer && customer.lastResults">
              <v-col>
                <card>&lt;!&ndash;:isDisabled="!customer.active"&ndash;&gt;
                  <template v-slot:body>
                    <div class="text-h6 font-weight-bold black&#45;&#45;text mb-4" style="position: relative">Last Surveys</div>
                    <div v-for="survey in customer.lastResults.$values" :key="survey.$id" @click="openReport('/visit', survey.visitId)">
                      <v-tooltip left color="white">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="link-block" color="primary" v-bind="attrs" v-on="on">
                            <div class="label">
                              &lt;!&ndash;                        <img :src="getImgWithExt(['icons', 'bottle-sup', 'svg'])"/>&ndash;&gt;
                              <v-icon color="#718096">mdi-badge-account-outline</v-icon>
                              <div class="label__name">{{$moment(survey.visitDate).format('ll')}}</div>
                            </div>
                            <div class="arrow">
                              <v-icon>mdi-chevron-right</v-icon>
                            </div>
                          </div>
                        </template>
                        <v-card flat class="card">
                          <v-card-title>
                            <div class="card-header">
                              <div class="card-header__title">Survey Details</div>
                              <div class="card-header__date">{{$moment(survey.visitDate).format('l')}}</div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="card-body">
                              <span class="card-body__title">Notes:</span>
                              <span class="card-body__text">{{survey.visitNotes || '-'}}</span>
                            </div>
                            <div class="card-body">
                              <span class="card-body__title">Complaints:</span>
                              <span class="card-body__text">{{survey.surveyComplaints || '-'}}</span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tooltip>
                    </div>
                  </template>
                </card>
              </v-col>
            </v-row>-->
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <add-patient v-if="dialogs.editPatient.isOpen" @showPopup="showPopup"/>
    <add-visit :query="query" v-if="dialogs.addVisit.isOpen" @showPopup="showPopup" />
    <desktop-download-link v-if="dialogs.modalDesktopDownloadLink.isOpen" @showPopup="showPopup"/>
    <confirm-new-customer-email v-if="dialogs.modalConfirmEmail.isOpen" @showPopup="showPopup"/>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
        <router-link v-if="newPatientLink" :to="`customers/${newPatientLink.id}`">
          <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">Patient Details</span></router-link>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Button from '@/components/elements/Button'
import card from '@/components/layouts/card'
import addPatient from '@/components/layouts/dialogs/add-patient'

import Pagination from '@/mixins/Pagination'
import Tabs from '@/components/layouts/Tabs'
import payments from '@/views/customers/payments'
import chat from '@/views/customers/chat'
import migrations from '@/views/customers/migrations'
import events from '@/views/customers/events'
import Image from '@/mixins/Image'
import addVisit from '@/components/layouts/dialogs/add-visit'
import DesktopDownloadLink from '@/components/layouts/dialogs/desktop-download-link'
import ConfirmNewCustomerEmail from "@/components/layouts/dialogs/confirm-new-customer-email.vue";

const SURVEY_TAB_INDEX = 0
const RESULT_TAB_INDEX = 2

export default {
  name: 'view-customer',
  mixins: [Pagination, Image],
  components: { ConfirmNewCustomerEmail, DesktopDownloadLink, Button, addPatient, card, Tabs, payments, chat, migrations, events, addVisit },
  data: () => ({
    customer: null,
    componentInd: 0,
    tabIndex: 0,
    popupMessage: null,
    newPatientLink: null,
    alertIsOpen: false,
    brand: [],
    actionList: [
      {
        name: 'Send Download Link',
        method: 'openModalDesktopDownloadLink'
      },
      {
        name: 'Confirm Email',
        method: 'onConfirmEmail'
      },
      {
        name: 'Reset Password',
        method: 'resetPassword'
      },
      {
        name: 'Update Password',
        method: 'updatePassword'
      },
      {
        name: 'Email change',
        method: 'emailChange'
      }
    ]
  }),
  created() {
    sessionStorage.setItem('selectedPage', this.$route.path)
  },
  async mounted () {
    const { id } = this.$route?.params
    if (id) {
      this.addUrlParam({ includeVisitsHistory: true })
      await this.getCustomer({ id, urlParams: this.query })
      this.customer = Object.assign({}, this.$store.state.customers.customer)
      this.brand = this.customer.brandVendorCodes.$values
      this.SET_PAGE_SETTINGS({ title: `${this.customer.firstName} ${this.customer.lastName}` })
    }

    if (this.$route.hash === '#chat') {
      this.$nextTick(() => {
        this.scrollToChat()
      })
    }
  },
  destroyed () {
    this.SET_PAGE_SETTINGS(null)
  },
  methods: {
    ...mapActions('customers', ['getCustomer', 'switchQrLeadsFeature']),
    ...mapActions('brands', ['selectBrandVendor']),
    ...mapActions('organizations', ['sendPasswordReset', 'sendConfirmEmail']),
    ...mapMutations(['SET_PAGE_SETTINGS']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    runMethod(method) {
      this[method]()
    },
    async resetPassword() {
      this.alertIsOpen = false
      const res = await this.sendPasswordReset({ organizationid: this.$route.params.id })
      this.alertIsOpen = true
      if (res) {
        this.popupMessage = 'Password reset successfully.'
      } else {
        this.popupMessage = res.errorMessage || 'Occurred some error.'
      }
    },
    updatePassword() {
      this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: true, title: 'Manual Password Update', data: 'Customer password', type: 'updatePassword', organizationid: this.$route.params.id })
    },
    emailChange() {
      this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: true, title: 'Change Email', data: 'New email', type: 'changeEmail', organizationid: this.$route.params.id })
    },
    openModalDesktopDownloadLink () {
      this.TOGGLE_DIALOG({ name: 'modalDesktopDownloadLink', isOpen: true, title: 'Desktop download link', data: 'Link' })
    },
    async onConfirmEmail () {
      const res = await this.sendConfirmEmail({ email: this.customer.email })
      if (res) {
        await this.showPopup({ type: 'confirm-email', data: res })
      } else {
        await this.showPopup({ type: 'error', data: res })
      }
    },
    enableLeadsFeature ($event) {
      const query = $event ? 'enable' : 'disable'
      this.switchQrLeadsFeature({ id: this.$route?.params.id, query })
    },
    async showPopup (res) {
      this.alertIsOpen = false
      this.alertIsOpen = true
      this.newPatientLink = res.type === 'add-patient' ? res.data : null
      if (res.type === 'edit-patient') {
        this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
        this.addUrlParam({ includeVisitsHistory: true })
        await this.getCustomer({ id: this.$route.params.id, urlParams: this.query })
        this.customer = Object.assign({}, this.$store.state.customers.customer)
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.popupMessage = 'New Evaluation Date was created  successfully.'
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
      }
      if (res.type === 'desktop-download-link') {
        this.TOGGLE_DIALOG({ name: 'modalDesktopDownloadLink', isOpen: false })
        this.popupMessage = 'Link was sent successfully.'
      }
      if (res.type === 'update-password') {
        this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: false })
        this.popupMessage = 'Password was updated successfully.'
      }
      if (res.type === 'confirm-email') {
        this.popupMessage = res.data.message || 'Email was confirmed successfully.'
      }
      if (res.type === 'change-email') {
        this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: false })
        this.popupMessage = res.data.message || 'Email was changed successfully.'
      }
      if (res.type === 'error') {
        this.popupMessage = res.errorMessage || 'Occurred some error.'
      }
    },
    edit ($event) {
      $event.preventDefault()
      const { id } = this.$route?.params
      if (id) {
        this.TOGGLE_DIALOG({ id, name: 'editPatient', isOpen: true, title: 'Edit patient' })
      }
    },
    navToResult (id) {
      this.$router.push({ path: `/visit/${id}`, query: { tabIndex: RESULT_TAB_INDEX } })
    },
    navToSurvey (id) {
      this.$router.push({ path: `/visit/${id}`, query: { tabIndex: SURVEY_TAB_INDEX } })
    },
    tab ($event) {
      this.componentInd = $event

      if (this.$route.hash === '#chat' && this.componentInd !== this.getTabIndexFromHash())
        window.history.pushState("", document.title, window.location.pathname + window.location.search)
    },
    sendEmailToPatient (email) {
      if (email) {
        window.open('mailto:' + email)
      }
    },
    openReport (path, id) {
      this.$router.push({ path: `${path}/${id}` })
    },
    getTabIndexFromHash() {
      if (this.$route.hash === '#chat')
        return this.tabs.findIndex(elem => elem.component === 'chat') || 0
      return 0
    },
    scrollToChat() {
      const chatElement = document.getElementById('chat')
      if (chatElement) {
        setTimeout(() => {
          const chatRect = chatElement.getBoundingClientRect()
          const chatOffset = chatRect.top + window.scrollY
          const additionalOffset = 100

          window.scrollTo({
            top: chatOffset - additionalOffset,
            behavior: 'smooth'
          })
        }, 500)
      }
    }
  },
  computed: {
    ...mapState({
      organization: state => state.organization,
      userProfile: state => state.userProfile,
    }),
    dialogs () {
      return this.$store.state.dialogs
    },
    tabs () {
      if (this.customer.isLegacy) {
        return [
          { title: 'chat', component: 'chat' },
          { title: 'payments', component: 'payments' },
          { title: 'events', component: 'events' },
          { title: 'migrations', component: 'migrations' }
        ]
      } else {
        return [
          { title: 'chat', component: 'chat' },
          { title: 'payments', component: 'payments' },
          { title: 'events', component: 'events' }
        ]
      }
    }
  },
  watch: {
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    },
    async brand (val) {
      if (val !== this.customer.brandVendorCodes.$values) {
        const data = {
          organizationId: this.$route.params.id,
          brandVendorCodes: this.brand
        }
        await this.selectBrandVendor(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    color: #425466;
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
  }
  .view-patient {
    position: relative;
    background-color: #F7FAFC;
    &__image {
      position: relative;
      .status {
        position: absolute;
        right: -5px;
        bottom: -15px;
      }
    }
    .custom-switch-label {
      ::v-deep .v-input__control {
        .v-input__slot {
          justify-content: end;
          margin-bottom: 0!important;
        }
        .v-messages {
          display: none;
        }
      }
    }
    .last-visit-date {
      font-size: 11px;
      font-weight: 300;
      color: #00000099;
      right: -10px;
      top: -25px;
      position: absolute;
    }
    .visits-list {
      background: #E9EEF3;
      border-radius: 16px;
      padding-right: 30px;
      &__date {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #425466;
      }
      &__doctor {
        font-size: 12px;
        line-height: 20px;
        color: #718096;
      }
      &__note {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #425466;
      }
      &__navs {
        height: 100%;
        .v-icon {
          border-radius: 6px;
          padding: 4px;
        }
        .file {
          background: rgba(115, 217, 149, 0.2);
          border-radius: 10px;
        }
        .briefcase {
          background: rgba(208, 220, 255, 0.5);
          border-radius: 10px;
        }
      }
    }
    ::v-deep .v-expansion-panel-header {
      .v-icon {
        margin-left: 6px;
        border-radius: 6px;
        border: 1px solid #A0AEC0;
      }
    }
    &__fullname {
      font-size: 20px;
      font-weight: 600;
      color: #16192C;
    }
    &__joined {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #718096;
    }
    .v-icon {
      cursor: pointer;
    }
    .custom-alert {
      max-width: 300px;
      position: absolute;
      top: -50px;
      right: 20px;
      z-index: 100;
    }
    .link-block {
      margin-bottom: 7px;
      align-items: center;
      width: 100%;
      padding: 18px 20px;
      background: #F7FAFC;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      cursor: pointer;
      .label {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: space-between;
        &__name {
          font-weight: 600;
          font-size: 14px;
          color: #425466;
        }
      }
    }
  }
  .view-patient.blur {
    filter: blur(2px);
  }
  .v-tooltip__content {
    padding: 0!important;
    opacity: 1!important;
    border: 1px solid grey!important;
    border-radius: 10px!important;
  }
  ::v-deep {
    .card {
      border-radius: 10px!important;
      .v-card__title {
        background: #edf2f7!important;
        min-width: 250px;
      }
    }
    .card-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &__title {
        font-size: 15px;
        font-weight: bold;
        color: black;
      }
      &__date {
        font-size: 15px;
        font-weight: bold;
        color: grey;
      }
    }
    .card-body {
      &__title {
        font-size: 15px;
        font-weight: bold;
        color: grey;
      }
      &__text {
        color: black;
        font-size: 13px;
      }
    }
  }
</style>
