import Vue from 'vue'

const state = () => ({
  bloodRanges: {},
  bloodRangesListIsLoaded: false
})

const getters = {}

const actions = {
  async getBloodRanges ({ commit }) {
    const result = await Vue.$http.get('blood/range/get-list')
    commit('SET_DATA', ['bloodRangesListIsLoaded', true])
    if (result?.status === 200) {
      commit('SET_DATA', ['bloodRanges', result?.data])
      return result?.data
    }
  },
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

