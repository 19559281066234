<template>
  <div class="customers">
    <template v-if="$route.name === 'Customers'">
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="patients__quick-filter py-0 d-flex flex-start">
            <quick-filter :items="qFilterBtns" ref="qFilter" />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="px-10 py-0" cols="12">
            <div class="d-flex">
              <div class="customers__total d-flex align-center mr-10"><span class="num mr-2">{{total}}</span> Customers</div>
              <div class="d-flex align-center mr-6" v-if="qFilterBtns && qFilterBtns[qFilterIndex].code === 'FollowUp'">
                <div class="mr-4">Follow Up date range <!--<br>today VISIT-->
                </div>
                <datepicker class="mr-2" :max="customers.followUpDateTo"
                            style="width: 170px;margin-top: 17px"
                            placeholder="From"
                            :value="$moment(query.followUpDateFrom).format('MM/DD/YYYY')"
                            @date:update="query.followUpDateFrom = $event;getCustomers(query)"/>
                <datepicker :min="customers.followUpDateFrom"
                            style="width: 170px;margin-top: 17px"
                            placeholder="To"
                            :value="$moment(query.followUpDateTo).format('MM/DD/YYYY')"
                            @date:update="query.followUpDateTo = $event;getCustomers(query)"/>
              </div>
              <div class="d-flex flex-grow-1 justify-end" style="align-items: center">
                <Input class="mr-2"
                       style="margin-top: 17px"
                       outlined
                       radius="8"
                       appendIcon="mdi-magnify"
                       placeholder="Search"
                       v-model="searchValue"
                       @input="inputSearch"
                       @enter="getCustomers(query)"
                />
                <Button class="mr-6"
                        btn-bg="dark-green"
                        @click.native="refresh"
                >
                <span style="color: #FFFFFF">
                  Refresh
                </span>
                </Button>
<!--                <Button btn-bg="dark-green"
                        @click.native="TOGGLE_DIALOG({ name: 'addPatient', isOpen: true, title: 'Add Patient' })">
                      <span style="color: #FFFFFF">
                        <v-icon>mdi-plus</v-icon>
                        Add customer
                      </span>
                </Button>-->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-5 pb-10 pr-5" style="position:relative;">
<!-- :actionsWithActivate="actionsWithActivate"            -->
            <Table class="patient-table"
                   hide-default-footer
                   :headers="headers"
                   :items="customerItems"
                   :serverItemsLength="total"
                   @update:option="optionTableData($event, 'asc')"
                   @click:action="openDialog($event)">
              <template v-slot:header:name>
                <filter-box :headers="headers" field-name="name" type="String" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>
              <template v-slot:header:status>
                <filter-box :headers="headers" field-name="status" type="FixedEnum" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>
              <template v-slot:header:nextVisitDate>
                <filter-box :headers="headers" field-name="nextVisitDate" type="Date" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>
              <template v-slot:header:lastVisitDate>
                <filter-box :headers="headers" field-name="lastVisitDate" type="Date" @update:filter-box="filterBox" @reset:filter-box="resetFilter" />
              </template>

              <template v-slot:item:name={item}>
                <div class="d-flex align-center" @click.stop="openPatientDetails(item.id)">
                  <v-checkbox
                    color="#008C89"
                    v-if="activeBatchSelectionMode"
                    @click.stop
                    @change="setCustomersForDesktopDownloadLink($event, item.id)"
                  ></v-checkbox>
                  <v-avatar class="patient-table__icon mr-2" size="40">
                    <img v-if="item.icon" :src="item.icon" :alt="item.name" />
                  </v-avatar>
                  <div class="patient-table__name d-flex align-center">
                    <span class="mr-2">{{item.name}}</span>
                  </div>
                </div>
              </template>

              <template v-slot:item:adminName={item}>
                <div class="d-flex test" @click.stop="openPatientDetails(item.id)">
                  <div class="patient-table__name d-flex align-center">
                    <span class="mr-2">{{item.adminName.trim() || '-'}}</span>
                  </div>
                </div>
              </template>

              <template v-slot:item:subscription={item}>
                <div class="d-flex" @click.stop="openPatientDetails(item.id)">
                  <div class="patient-table__name d-flex align-center">
                    <span class="mr-2">{{item.subscription || '-'}}</span>
                  </div>
                </div>
              </template>

              <template v-slot:item:createdOn="{ item }">
                <div class="d-flex" @click.stop="openPatientDetails(item.id)">
                  <div class="patient-table__name d-flex align-center">
                    <span class="mr-2">{{$moment(item.createdOn).format('MM/DD/YYYY')}}</span>
                  </div>
                </div>
              </template>

              <template v-slot:item:email={item}>
                <div class="d-flex align-center" style="white-space: nowrap" @click.stop="sendEmailToPatient(item.email)"><!--width: 125px;-->
                  <v-icon color="#A0AEC0" class="mr-1">mdi-email-outline</v-icon>
<!--                  <Link to="#" color="#425466" size="14" weight="400" style="overflow: hidden;text-overflow: ellipsis;">
                    {{ item.email }}
                  </Link>-->
                </div>
              </template>

              <template v-slot:item:isLegacy={item}>
                <div class="d-flex align-center" @click.stop="openPatientDetails(item.id)">
                  <v-icon color="#008C89" v-if="item.isLegacy">mdi-check-circle</v-icon>
                  <span v-else>-</span>
                </div>
              </template>

              <template v-slot:item:fromOffSite={item}>
                <div class="d-flex align-center">
                  <v-icon color="#008C89" v-if="item.fromOffSite">mdi-check-circle</v-icon>
                  <span v-else>-</span>
                </div>
              </template>

              <template v-slot:item:fromLead={item}>
                <div class="d-flex align-center">
                  <v-icon color="#008C89" v-if="item.fromLead">mdi-check-circle</v-icon>
                  <span v-else>-</span>
                </div>
              </template>

              <template v-slot:item:status="{ item }">
                <div class="d-flex" @click.stop="openPatientDetails(item.id)">
                  <div class="patient-table__name d-flex align-center">
                    <span class="mr-2">{{item.status}}</span>
                  </div>
                </div>
              </template>

              <template v-slot:item:stripeCustomerId="{ item }">
                <div class="d-flex">
                  <div class="patient-table__name d-flex align-center">
                    <a  v-if="item.stripeCustomerId && item.stripeCustomerId.includes('cus')" :href="`https://dashboard.stripe.com/customers/${item.stripeCustomerId}`" target="_blank">{{item.stripeCustomerId}}</a>
                    <span class="mr-2" v-else>{{item.stripeCustomerId}}</span>
                  </div>
                </div>
              </template>

              <template v-slot:footer>
                <div class="footer pl-5 d-flex align-center">
                  <div class="row-per-page d-flex align-center flex-grow-1">
                    <span class="row-per-page__lable mr-4">Rows per page: </span>
                    <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[2]" @change="changeItermPerPage" />
                  </div>
                  <div class="flex-grow-2">
                    <pagination v-if="pageCountSystem" :value="page" :length="pageCountSystem" @input="changePagination" />
                  </div>
                  <div class="d-flex align-center justify-end flex-grow-1 mr-6">
                    <v-switch class="py-0 my-0 pr-2 custom-switch-label" color="#008C89" inset label="Batch selection mode" @change="batchSelectionMode" />
                    <v-menu offset-y v-if="activeBatchSelectionMode">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#008C89"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Batch Action
                          <v-icon>mdi-arrow-down-thin</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in batchActions"
                          :key="index"
                        >
                          <v-list-item-title style="cursor:pointer;" @click="handleDynamicMethod(item.method)">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </template>
            </Table>
            <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
              <v-progress-circular
                indeterminate
                color="#008C89"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <add-patient :query="query" v-if="dialogs.addPatient.isOpen || dialogs.editPatient.isOpen" @showPopup="showPopup" />
      <add-visit :query="query" v-if="dialogs.addVisit.isOpen" @showPopup="showPopup" />
      <confirm :query="query" v-if="dialogs.confirm.isOpen" />
      <invite-patient v-if="dialogs.invitePatient.isOpen"  @showPopup="showPopup" />
      <desktop-download-link v-if="dialogs.modalDesktopDownloadLink.isOpen" @showPopup="showPopup" />
      <div class="custom-alert">
        <v-alert
          color="#7ac1fb"
          dismissible
          type="info"
          v-model="alertIsOpen"
        >
          <span style="font-size: 14px;">{{popupMessage}}</span>
          <router-link v-if="newPatientLink" :to="`customers/${newPatientLink.id}`">
            <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">Patient Details</span></router-link>
        </v-alert>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import Table from '@/components/elements/Table'
import QuickFilter from '@/views/customers/quick-filter'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import datepicker from '@/components/elements/datepicker'
import pagination from '@/components/layouts/pagination'

import addPatient from '@/components/layouts/dialogs/add-patient'
import addVisit from '@/components/layouts/dialogs/add-visit'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import invitePatient from '@/components/layouts/dialogs/invite-patient'
import DesktopDownloadLink from '@/components/layouts/dialogs/desktop-download-link'

import filterBox from '@/components/layouts/filter-box'

import Pagination from '@/mixins/Pagination'
import Mapping from '@/mixins/Mapping'
import Select from '@/components/elements/Select.vue'

const GET_ALL_PACIENTS = 'getCustomers'
const IS_SLOTABLE = ['name', 'adminName', 'subscription', 'status', 'createdOn', 'email', 'phone', 'isLegacy', 'fromOffSite', 'stripeCustomerId', 'fromLead', 'actions']
const IS_FILTERABLE = ['icon', 'address']

export default {
  name: 'Customers',
  mixins: [Pagination, Mapping],
  components: { Table, QuickFilter, Button, Input, addPatient, addVisit, confirm, filterBox, datepicker, Select, pagination, invitePatient, DesktopDownloadLink },
  data: () => ({
    qFilterIndex: 0,
    itemsPerPage: 15,
    page: 1,
    defaultButtonActionsWithActivate: [
      { icon: 'mdi-eye-outline', color: 'grey', name: 'View customer', type: 0 },
      { icon: 'mdi-pencil-outline', color: 'grey', name: 'Edit patient', type: 1 },
      { icon: 'mdi-account-reactivate-outline', color: 'grey', name: 'Activate', type: 4 }
    ],
    searchValue: null,
    popupMessage: null,
    newPatientLink: null,
    alertIsOpen: false,
    activeBatchSelectionMode: false,
    batchActions: [
      {
        title: 'Send Download Link',
        method: 'openModalDesktopDownloadLink'
      }
    ],
    activeCustomersForDownloadLink: [],
    loading: true
  }),
  created () {
    this.appSettings()
  },
  mounted () {
    // Watch to index of quick-filter component
    // this.$watch('$refs.qFilter.index', this.applyQFilter)
    this.loading = true
    this.waitingRefs()
    if (this.$route.query.quickFilterCode) {
      this.addUrlParam({ quickFilterCode: this.$route.query.quickFilterCode })
      this.getCustomers(this.query)
    }
  },
  beforeDestroy () {
    this.SET_DATA(['customerListIsLoaded', false])
  },
  watch: {
    customers (val) {
      if (val) {
        this.query.followUpDateFrom = val.followUpDateFrom
        this.query.followUpDateTo = val.followUpDateTo
      }
    },
    '$route.name' (val) {
      if (val === 'Customers') {
        this.waitingRefs()
        this.$forceUpdate()
      }
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    },
    customerListIsLoaded (val) {
      if (val) {
        this.loading = false
      }
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('customers', ['SET_DATA']),
    ...mapActions(['appSettings']),
    ...mapActions('customers', ['getCustomers']),
    batchSelectionMode ($event) {
      this.activeBatchSelectionMode = $event
    },
    setCustomersForDesktopDownloadLink ($event, id) {
      if ($event) {
        this.activeCustomersForDownloadLink.push(id)
      } else {
        this.activeCustomersForDownloadLink = this.activeCustomersForDownloadLink.filter(item => item === id)
      }
    },
    handleDynamicMethod (method) {
      this[method]()
    },
    openModalDesktopDownloadLink () {
      this.TOGGLE_DIALOG({ name: 'modalDesktopDownloadLink', isOpen: true, title: 'Desktop download link', data: 'Desktop download link', customers: this.activeCustomersForDownloadLink })
    },
    refresh () {
      this.searchValue = null
      const query = Object.assign({}, this.query)
      delete query.search
      delete query.columns
      this.addUrlParam({ ...query })
      this.$nextTick(() => {
        this.getCustomers(query)
      })
    },
    waitingRefs () {
      const interval = setInterval(() => {
        if (this.$refs.qFilter) {
          console.log(this.$refs.qFilter)
          this.$watch('$refs.qFilter.index', this.applyQFilter)
          clearInterval(interval)
        }
      }, 50)
    },
    showPopup (res) {
      console.log('showPopup', res.type, res.data)
      this.alertIsOpen = true
      switch (res.type) {
        case 'add-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was created  successfully. You can see at `
          this.newPatientLink = res.data
          break
        case 'edit-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
          break
        case 'add-visit':
          this.popupMessage = 'Evaluation date was added. The invitation was sent to Patient Email.'
          break
        case 'invite-patient':
          this.popupMessage = 'The Invitiation Email was sent to the Patient. If Patient will confirm the registration he will be able to join the Patient Portal and fill in the Survey(s).'
          break
        case 'desktop-download-link':
          this.popupMessage = 'Link was sent successfully.'
      }
      if (res.type === 'edit-patient') {
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
      }
      if (res.type === 'invite-patient') {
        this.TOGGLE_DIALOG({ name: 'invitePatient', isOpen: false })
      }
      if (res.type === 'desktop-download-link') {
        this.TOGGLE_DIALOG({ name: 'modalDesktopDownloadLink', isOpen: false })
      }
    },
    inputSearch ($event) {
      this.query.PageIndex = 1
      this.page = 1
      this.addUrlParam({ search: { searchType: 1, value: $event } })
    },
    applyQFilter (val) {
      console.log('applyQFilter', val)
      this.qFilterIndex = val
      this.query.PageIndex = 1
      this.addUrlParam({ quickFilterCode: this.qFilterBtns[val].code })
      if (val === 3) {
        this.addUrlParam({
          followUpNotOlderDays: '30',
          followUpDaysFromToday: '60'
        })
      } else {
        this.removeUrlParam('followUpNotOlderDays')
        this.removeUrlParam('followUpDaysFromToday')
      }
      this.getCustomers(this.query)
    },
    cutName (name) {
      const [f, l] = name.split(' ')
      return this._.toUpper(f.charAt(0) + l.charAt(0))
    },
    openPatientDetails (id) {
      this.$router.push({ name: 'View customer', params: { id } })
    },
    callToPatient (phoneNumber) {
      if (phoneNumber) {
        window.open('tel:' + phoneNumber)
      }
    },
    sendEmailToPatient (email) {
      if (email) {
        window.open('mailto:' + email)
      }
    },
    openDialog ($event) {
      switch ($event.action.type) {
        case 0:
          this.$router.push({ path: `customers/${$event.id}` })
          break
        case 1:
          this.TOGGLE_DIALOG({ id: $event.id, name: 'editPatient', isOpen: true, title: $event.action.name })
          break
        case 2:
          this.TOGGLE_DIALOG({
            id: $event.id,
            name: 'confirm',
            isOpen: true,
            action: 'customers/deactivatePatient',
            afterAction: { name: 'customers/getCustomers' },
            title: 'Confirm deactivating the Patient',
            description: `Please confirm Patient <b>${$event.data.firstName} ${$event.data.lastName}</b> deactivation. If Patient will be marked as Inactive you will not see him in Recent or Follow Up areas and patient will not be able to access the Patient Portal if registered.<br>Are you sure want to deactivate the Patient?`
          })
          break
        case 3:
          this.TOGGLE_DIALOG({ id: $event.id, name: 'invitePatient', isOpen: true, title: $event.action.name, data: $event.data })
          break
        case 4:
          this.TOGGLE_DIALOG({
            id: $event.id,
            name: 'confirm',
            isOpen: true,
            action: 'customers/activatePatient',
            afterAction: { name: 'customers/getCustomers' },
            title: 'Confirm activating the Patient',
            description: `Please confirm Patient <b>${$event.data.firstName} ${$event.data.lastName}</b> activation.<br>Are you sure want to activate the Patient?`
          })
          break
      }
    },
    inactivePaitent ($event) {
      this.addUrlParam({ showInactive: $event })
      this.getCustomers(this.query)
    },
    filterBox ($event) {
      const columns = []
      columns.push($event)
      this.addUrlParam({ columns })
      this.getCustomers(this.query)
    },
    resetFilter ($event) {
      const query = Object.assign({}, this.query)
      const columns = query.columns.filter(el => el.name !== $event)
      this.addUrlParam({ columns })
      this.getCustomers(this.query)
    },
    changeItermPerPage ($event) {
      this.itemsPerPage = $event
      if ($event !== this.query.PageSize) {
        this.addUrlParam({
          PageIndex: this.page,
          PageSize: $event
        })
        this.getCustomers(this.query)
      }
    },
    changePagination ($event) {
      this.page = $event
      localStorage.setItem('patientPaginationPageIndex', $event)
      this.addUrlParam({
        PageIndex: $event,
        PageSize: this.itemsPerPage
      })
      this.getCustomers(this.query)
    }
  },
  computed: {
    ...mapState('customers', {
      headers (state) {
        const headers = Object.assign([], state.customers?.headers?.$values)
        // headers.push({ text: 'Actions', value: 'actions', isSlotable: true, width: '1%' })
        return headers?.map(el => ({
          ...this._.omit(el, ['skip', '$id']),
          isSlotable: IS_SLOTABLE.includes(el.value),
          width: (this._.isNumber(el.width)) ? el.width + '%' : el.width
        })).filter(el => !IS_FILTERABLE.includes(el.value))
      },
      customerItems (state) {
        const items = Object.assign([], state.customers?.data?.$values)
        return items
      },
      total (state) {
        return state.customers?.pagination?.total
      },
      customers (state) {
        return state.customers
      }
    }),
    pageCountSystem () {
      return (this.itemsPerPage) ? Math.ceil(this.total / this.itemsPerPage) : 1
    },
    getAllFunc: () => GET_ALL_PACIENTS,
    dialogs () {
      return this.$store.state.dialogs
    },
    qFilterBtns () {
      return this.$store.state.settings?.patients?.practiceFilters?.$values
    },
    statuses () {
      return this.$store.state.settings?.customers?.statuses?.$values
    },
    actionsWithActivate () {
      return this.defaultButtonActionsWithActivate
    },
    rowPerPageItems: () => ([5, 10, 15, 20]),
    customerListIsLoaded () {
      return this.$store.state.customers?.customerListIsLoaded
    }
  }
}
</script>

<style lang="scss" scoped>
  .customers {
    background-color: #F7FAFC;
    height: 100vh;
    min-height: 100px;
    .loading {
      height: 100%;
      min-height: 100px;
      position: absolute;
      width: 98%;
      top: 0;
      left: 20px;
      background: rgba(213, 213, 213, 0.5);
      border-radius: 16px;
    }
    .custom-switch-label {
      ::v-deep .v-input__control {
        .v-input__slot {
          margin-bottom: 0!important;
        }
        .v-messages {
          display: none;
        }
      }
    }
    ::v-deep .v-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
    }
    .next-visit {
      &__add {
        ::v-deep .v-btn {
          font-weight: 500;
          font-size: 10px;
          line-height: 10px;
          color: $dark-green;
          border: 1px solid $dark-green !important;
        }
      }
      &__exist {
        max-width: 145px;
        .link {
          width: auto;
          padding-left: 14px;
          font-weight: 400;
        }
        &:hover {
          background: #F7FAFC;
          border-radius: 8px;
          padding: 4px 0;
        }
      }
    }
    &__quick-filter {
      background-color: #FFF;
      border-bottom: 1px solid #EDF2F7;
    }
    &__total {
      font-size: 20px;
      font-weight: 600;
      span.num {
        color: $dark-green;
      }
    }
    .v-icon {
      cursor: pointer;
    }
    ::v-deep .v-btn {
      border-radius: 8px;
      &__content {
        font-weight: 600;
      }
    }
    ::v-deep .v-text-field {
      margin: 0;
      padding: 0;
      &__details {
        //display: none;
        margin-bottom: 4px;
      }
    }
    .patient-table {
      &__name {
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
    .footer {
      height: 60px;
      ::v-deep .v-input {
        .v-messages {
          //display: none;
        }
        &__control {
          .v-text-field__details {
            display: none!important;
          }
        }
      }
      .row-per-page {
        font-size: 14px;
        color: #718096;
        &__list {
          width: 60px;
        }
        ::v-deep .v-menu__content {
          min-width: 62px !important;
          .v-list-item {
            &__title {
              display: flex;
              justify-content: center;
            }
          }
        }
        ::v-deep .v-input {
          &__slot {
            margin: 0 !important;
          }
          &__append-inner {
            margin-left: 0;
            padding-left: 0;
            margin-top: 2px !important;
          }
        }
        ::v-deep .v-select {
          input {
            display: none;
          }
          &__selection {
            font-size: 14px;
            color: #718096;
          }
          &__selections {
            flex: unset !important;
            line-height: 17px;
            margin-left: 5px;
          }
        }
      }
      ::v-deep .v-input {
        &__slot {
          margin: 0 !important;
        }
      }
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
    .patient-is-registered {
      border-radius: 50%;
      border: 2px solid green;
    }

    ::v-deep .status {
      .v-chip {
        cursor: pointer!important;
        background: unset!important;
        color: unset!important;
        width: -webkit-fill-available!important;
        justify-content: center!important;
      }
    }
    .need-product {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .in-active {
      background: #EDF2F7!important;
      color: #A0AEC0!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .need-survey {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .need-reeval {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .survey-sent {
      background: rgba(208, 220, 255, 0.5);
      color: #668BFF;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .survey-due {
      background: rgba(255, 230, 228, 0.7)!important;
      color: #F16063!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .need-review {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .reviewed {
      background: rgba(208, 220, 255, 0.5)!important;
      color: #668BFF!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .in-protocol {
      background: rgba(115, 217, 149, 0.2)!important;
      color: #4FAA6E!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
    .completed {
      background:  rgba(115, 217, 149, 0.2)!important;
      color: #4FAA6E!important;
      border-radius: 6px!important;
      text-align: center!important;
    }
  }
</style>
