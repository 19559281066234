import Vue from 'vue'

const state = () => ({
  processes: {},
  processesListIsLoaded: false
})

const getters = {}

const actions = {
  async getProcesses ({ commit }, params) {
    const result = await Vue.$http.post('helper/processes', { ...params });
    commit('SET_DATA', ['processesListIsLoaded', true])
    if (result?.status === 200) {
      commit('SET_DATA', ['processes', result?.data])
      return result?.data
    }
  },
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
