<template>
  <div class="processes">
    <template v-if="$route.name === 'Processes'">
      <div class="processes__quick-filter d-flex flex-start">
        <quick-filter :items="processTypes" ref="qFilter" />
      </div>
      <div class="main-row mb-3">
        <div class="processes__total d-flex align-center mr-10"><span class="num mr-2">{{ total }}</span> Processes</div>
        <div class="d-flex align-center flex-grow-1 justify-end">
          <v-col class="px-0 py-0 mx-0 my-0 ml-5 d-flex align-center show-errors">
            <v-checkbox
              class="px-0 py-0 my-0"
              color="#008C89"
              v-model="onlyErrors"
              @change="handleCheckboxChange"
              label="Show only Errors"/>
          </v-col>
          <Button outlined
                  :styles="{ 'border': '1px solid #A6B7D4', 'min-width': '36px', 'padding': '0px', 'margin': '0 0 0 32px' }"
                  @click.native="refresh"
          >
            <Img :src="getImgWithExt(['icons', 'refresh', 'svg'])" />
          </Button>
        </div>
      </div>
      <div class="main-row" style="position:relative;">
        <Table
          :headers="headers"
          :items="processesItems"
          class="processes-table"
          @update:option="optionTableData($event, 'asc')"
          @filterBox="filterBox"
          @resetFilter="resetFilter"
          hideDefaultFooter>
          <template v-slot:body>
            <tr
              v-for="item in processesItems"
              :key="item.id"
              class="blood-ranges-table__row"
            >
              <td class="px-4 py-4">
                <div class="d-flex align-start width100 height100" style="position: relative;">
                  <span class="font-bold">{{ item.type }}</span>
                  <v-tooltip class="info" top attach="parent">
                    <template v-slot:activator="{ on, attrs }">
                      <Img style="padding-left: 5px; cursor: pointer" :src="getImgWithExt(['icons', 'info-icon', 'svg'])" alt="info" v-bind="attrs" v-on="on" />
                    </template>
                    <span>{{ item.info }}</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="px-4 py-4" style="position: relative;">
                <div class="align-center width100 height100" style="display: grid; grid-template-columns: 1fr 110px 40px; gap: 10px;">
                  <div class="d-flex align-start">
                    <v-tooltip class="error" top v-if="item.status?.toLowerCase() === 'error'" attach="parent">
                      <template v-slot:activator="{ on, attrs }">
                        <Img style="padding-right: 4px; cursor: pointer" :src="getImgWithExt(['icons', 'info-icon-error', 'svg'])" alt="info" v-bind="attrs" v-on="on" />
                      </template>
                      <span>{{ item.message }}</span>
                    </v-tooltip>
                    <span class="font-normal" :class="{ 'text-error': item.status?.toLowerCase() === 'error' }">{{ item.status }}</span>
                  </div>
                  <div class="progress-bar-container">
                    <div
                      class="progress-bar"
                      :style="{
                      width: item.progress + '%',
                      background: getProgressBarColor(item.status, item.progress)
                      }">
                    </div>
                  </div>
                  <span class="percent">{{ item.progress }}<span>%</span></span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ $moment(item.startedOn).format('DD/MM/YYYY hh:mm') }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ formatExecutionTime(item.executeTime) }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.totalRecords }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.processedRecords }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.unprocessedRecords }}</span>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <div class="footer pl-5 d-flex align-center">
              <div class="row-per-page d-flex align-center flex-grow-1">
                <span class="row-per-page__lable mr-4">Rows per page: </span>
                <Select
                  class="row-per-page__list"
                  radius="8"
                  color="#B5B5BD"
                  :items="rowPerPageItems"
                  :value="rowPerPageItems[2]"
                  @change="changeItemsPerPage"
                />
              </div>
              <div class="flex-grow-2">
                <pagination
                  v-if="pageCountCustom"
                  :value="page"
                  :length="pageCountCustom"
                  :total-visible="7"
                  @input="changePagination"
                />
              </div>
              <div class="d-flex align-center justify-end flex-grow-1 mr-6" style="column-gap: 1rem"></div>
            </div>
          </template>
        </Table>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Pagination from '@/mixins/Pagination'
import Image from '@/mixins/Image'
import QuickFilter from '@/views/customers/quick-filter'
import Button from "@/components/elements/Button.vue"
import Table from "@/components/elements/Table.vue"
import Select from "@/components/elements/Select.vue"
import pagination from '@/components/layouts/pagination'

const GET_ALL_PROCESSES = 'getProcesses'

export default {
  name: 'processes',
  components: { Table, Select, QuickFilter, Button, pagination },
  mixins: [ Pagination, Image ],
  data: () => ({
    page: 1,
    itemsPerPage: 15,
    onlyErrors: false,
    loading: true,
  }),
  mounted () {
    this.loading = true
    this.appSettings()
    this.waitingRefs()
  },
  methods: {
    ...mapMutations('processes', ['SET_DATA']),
    ...mapActions('processes', ['getProcesses']),
    ...mapActions(['appSettings']),
    filterBox ($event) {
      const columns = []
      columns.push($event)
      this.addUrlParam({ columns })
      this.getProcesses(this.query)
    },
    resetFilter ($event) {
      const query = Object.assign({}, this.query)
      const columns = query.columns.filter(el => el.name !== $event)
      this.addUrlParam({ columns })
      this.getProcesses(this.query)
    },
    refresh () {
      this.page = 1
      this.query.PageSize = this.currentPageSize
      this.query.PageIndex = 0
      this.onlyErrors = false
      console.log('this.query', this.query)
      const query = Object.assign({}, this.query)
      console.log('refresh', query)
      this.addUrlParam({ ...query })
      this.$nextTick(() => {
        this.getProcesses(query)
      })
    },

    updateQuickFilterValue (val) {
      this.query.PageIndex = 1
      const selectedFilter = this.processTypes[val]
      if (selectedFilter.code !== 'AllType') {
        this.addUrlParam({ quickFilterCode: selectedFilter.code })
      } else {
        this.removeUrlParam('quickFilterCode')
      }
      this.getProcesses({
        ...this.query,
        onlyErrors: this.onlyErrors
      })
    },

    waitingRefs () {
      const interval = setInterval(() => {
        if (this.$refs.qFilter) {
          console.log(this.$refs.qFilter)
          this.$watch('$refs.qFilter.index', this.updateQuickFilterValue)
          clearInterval(interval)
        }
      }, 50)
    },

    changeItemsPerPage($event) {
      this.itemsPerPage = $event
      if ($event !== this.query.PageSize) {
        this.addUrlParam({
          PageIndex: this.page - 1,
          PageSize: $event
        })
        this.getProcesses(this.query)
      }
    },

    changePagination($event) {
      console.log('changePagination', this.itemsPerPage)
      this.page = $event
      this.addUrlParam({
        PageIndex: this.page - 1,
        PageSize: this.itemsPerPage
      })
      this.getProcesses(this.query)
    },

    formatExecutionTime(time) {
      if (!time) return ''
      const [minutes, seconds] = time.split(':').map(Number)
      return `${minutes} min ${seconds} s`
    },

    getProgressBarColor(status, progress) {
      if (status === 'Completed') {
        return '#40BFB4'
      } else if (status === 'Processing') {
        return `linear-gradient(90deg, #FFEF5E 0%, #F7936F ${progress}%)`
      } else if (status === 'Error') {
        return '#D46A51'
      }
      return '#EDF2F7'
    },
    handleCheckboxChange() {
      this.getProcesses({ onlyErrors: this.onlyErrors })
    },
  },
  computed: {
    ...mapState('processes', {
      headers() {
        return [
          { text: 'Process Type', value: 'processType', align: 'Left', sortable: true, width: 200 },
          { text: 'Status', value: 'status', align: 'Left', sortable: true, width: 100 },
          { text: 'Started on', value: 'startedOn', align: 'Left', sortable: true, width: 200 },
          { text: 'Execution Time', value: 'executionTime', align: 'Left', sortable: true, width: 80 },
          { text: 'Records', value: 'records', align: 'Left', sortable: true, width: 80 },
          { text: 'Processed', value: 'processed', align: 'Left', sortable: true, width: 80 },
          { text: 'Not Processed', value: 'notProcessed', align: 'Left', sortable: true, width: 80 },
        ];
      },
      processesItems (state) {
        const items = Object.assign([], state.processes?.data?.$values)
        return items
      },

      total (state) {
        return state.processes?.pagination?.total
      },

      processTypes() {
        const types = this.$store.state.settings?.patients?.processTypes?.$values || []
        const allType = {
          $id: "1",
          code: "AllType",
          name: "All Type",
        }
        return [allType, ...types]
      },
    }),

    pageCountCustom() {
      return (this.itemsPerPage) ? Math.ceil(this.total / this.itemsPerPage) : 1
    },

    rowPerPageItems: () => ([5, 10, 15, 20]),

    getAllFunc: () => GET_ALL_PROCESSES,

    processesListIsLoaded () {
      return this.$store.state.processes?.processesListIsLoaded
    }
  },
  beforeDestroy () {
    this.SET_DATA(['processesListIsLoaded', false])
  },
  watch: {
    '$route.name' (val) {
      if (val === 'Processes') {
        this.waitingRefs()
        this.$forceUpdate()
      }
    },
    processesListIsLoaded (val) {
      if (val) {
        this.loading = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.processes {
  background-color: #F7FAFC;
  height: 100vh;
  padding: 16px;
  min-height: 100px;

  &__quick-filter {
    overflow-x: auto;
    margin-bottom: 20px;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ::v-deep .v-btn {
    border-radius: 8px;
  }

  .show-errors {
    ::v-deep .v-input {
      margin-left: auto;

      &__control {
        &:hover {
          background-color: transparent !important;
        }

        .v-input__slot {
          align-items: end;
          margin-bottom: 0;

          label {
            color: #425466;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0;
          }

          .v-input--selection-controls__input {
            i {
              color: #C9CED6;
            }

            &:hover {
              .v-input--selection-controls__ripple {
                &:before {
                  background-color: transparent;
                }
              }
            }
          }
        }

        .v-messages {
          display: none;
        }
      }
    }
  }

  .loading {
    height: 100%;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(213, 213, 213, 0.5);
    border-radius: 16px;
  }

  &__total {
    font-size: 20px;
    font-weight: 600;

    span.num {
      color: $dark-green;
    }
  }

  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .processes-table {
    width: 100%;

    ::v-deep .v-card {
      height: auto!important;
    }

    ::v-deep .v-data-table-header th + th {
      border-left: 2px solid #F7FAFC !important;
    }

    ::v-deep th {
      height: 52px!important;
    }

    ::v-deep td {
      &:not(:first-child) {
        border-left: 2px solid #F7FAFC !important;
      }

      border-bottom: 1px solid #EDF2F7;
    }

    &__row {
      cursor: pointer;
    }

    &__row:hover {
      background-color: #008c8921!important;
    }

    &__row:last-child {
      td {
        border-bottom: 1px solid #EDF2F7;
      }
    }

    .font-bold {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.3;
      color: #2D3748;
    }

    .font-normal {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      color: #2D3748;

      &.text-error {
        color: #CF4655;
      }
    }

    .progress-bar-container {
      flex-shrink: 0;
      width: 107px;
      height: 4px;
      background-color: #EDF2F7;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
    }

    .progress-bar {
      height: 100%;
      border-radius: 4px;
    }

    .percent {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #425466;

      span {
        font-size: 14px;
      }
    }
  }
  .v-tooltip {
    display: block;

    .v-tooltip__content {
      position: absolute;
      top: -50% !important;
      left: 100% !important;
      background: rgb(255, 255, 255);
      box-shadow: rgba(113, 128, 150, 0.24) 0px 0px 15px 0px;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 400;
      width: 280px;
      opacity: 1 !important;
      padding: 20px 20px 18px;
      z-index: 100;
    }

    &.error .v-tooltip__content {
      color: #CF4655;
    }

    &.info .v-tooltip__content {
      color: #2D3748;
      top: -92px !important;
    }
  }

  .footer {
    height: 60px;

    .row-per-page {
      font-size: 14px;
      color: #718096;

      &__list {
        width: 60px;
      }

      ::v-deep .v-menu__content {
        min-width: 62px !important;

        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }

      ::v-deep .v-input {
        padding-top: 0 !important;
        margin-top: 0 !important;

        &__slot {
          margin: 0 !important;
        }

        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }

      ::v-deep .v-select {
        input {
          display: none;
        }

        &__selection {
          font-size: 14px;
          color: #718096;
        }

        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }

      .select {
        ::v-deep .v-input {
          &__control {
            .v-text-field__details {
              display: none !important;
            }
          }
        }
      }
    }

    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
}
</style>
