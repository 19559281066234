const fakeData = {
  menu: [
    /* {
      name: 'Home',
      icon: 'mdi-home-outline',
      link: '/',
      onlyAuth: true
    }, */
    {
      name: 'Customers',
      icon: 'mdi-account-group-outline',
      link: '/customers',
      onlyAuth: true
    },
    {
      name: 'Supplements',
      icon: 'mdi-bottle-tonic-outline',
      link: '/supplements',
      onlyAuth: true
    },
    {
      name: 'Protocols',
      icon: 'mdi-file-powerpoint-outline',
      link: '/protocols',
      onlyAuth: true
    },
    {
      name: 'Blood Ranges',
      icon: 'mdi-water-percent',
      link: '/blood-ranges',
      onlyAuth: true
    },
    {
      name: 'Processes',
      icon: 'mdi-cogs',
      link: '/processes',
      onlyAuth: true
    },
    {
      name: 'Setup',
      icon: 'mdi-credit-card-outline',
      link: '/setup',
      onlyAuth: true
    },
    {
      name: 'Collapse',
      icon: 'mdi-chevron-left',
      onlyAuth: true
    }
    // {
    //   name: 'Schedule',
    //   icon: 'mdi-format-list-checkbox-outline',
    //   link: '/schedule',
    //   onlyAuth: true
    // },
    // {
    //   name: 'Reports',
    //   icon: 'mdi-chart-areaspline-variant-outline',
    //   link: '/reports',
    //   onlyAuth: true
    // },
    // {
    //   name: 'HIPAA',
    //   icon: 'mdi-chart-timeline-outline',
    //   link: '/hipaa',
    //   onlyAuth: false
    // },
    // {
    //   name: 'Support',
    //   icon: 'mdi-face-agent-outline',
    //   link: '/support',
    //   onlyAuth: false
    // }
  ],
  states: [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'AmericanSamoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'DistrictOf Columbia', abbreviation: 'DC' },
    { name: 'FederatedStates Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'MarshallIslands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'NewHampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' }
  ]
}

export default fakeData
