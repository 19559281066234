<template>
  <div class="blood-ranges">
    <template v-if="$route.name === 'Blood Ranges'">
      <div class="main-row">
        <div class="blood-ranges__total d-flex align-center mr-10"><span class="num mr-2">{{ filteredItems.length }}</span> Blood Range</div>
        <div class="d-flex align-center flex-grow-1 justify-end">
          <Input class="mr-2"
                 style="margin-top: 22px"
                 outlined
                 radius="8"
                 appendIcon="mdi-magnify"
                 placeholder="Search"
                 @input="inputSearch"
                 :value="searchedText"
          />
          <Button btn-bg="dark-green"
                  class="mr-2"
                  @click.native="refresh"
                  :styles="{ 'border-radius': '8px' }"
          >
                <span style="color: #FFFFFF">Refresh</span>
          </Button>
        </div>
      </div>
      <div class="main-row" style="position:relative;">
        <Table
          :headers="headers"
          :items="paginatedItems"
          class="blood-ranges-table"
          @update:option="optionTableData($event, 'asc')"
          hideDefaultFooter>

          <template v-slot:body>
            <tr
              v-for="item in paginatedItems"
              :key="item.id"
              class="blood-ranges-table__row"
            >
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-bold">{{ item.groupName }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.markerName }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.functionalLow }} - {{ item.functionalHigh }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.labLow }} - {{ item.labHigh }}</span>
                </div>
              </td>
              <td class="px-4 py-4">
                <div class="d-flex align-center width100 height100">
                  <span class="font-normal">{{ item.units }}</span>
                </div>
              </td>
            </tr>
          </template>

          <template v-slot:footer>
            <div class="footer pl-5 d-flex align-center">
              <div class="row-per-page d-flex align-center flex-grow-1">
                <span class="row-per-page__lable mr-4">Rows per page: </span>
                <Select
                  class="row-per-page__list"
                  radius="8"
                  color="#B5B5BD"
                  :items="rowPerPageItems"
                  :value="rowPerPageItems[2]"
                  @change="changeItemsPerPage"
                />
              </div>

              <div class="flex-grow-2">
                <pagination
                  v-if="pageCountCustom"
                  :value="page"
                  :length="pageCountCustom"
                  :total-visible="7"
                  @input="changePagination"
                />
              </div>
              <div class="d-flex align-center justify-end flex-grow-1 mr-6" style="column-gap: 1rem"></div>
            </div>
          </template>
        </Table>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import Pagination from '@/mixins/Pagination'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import Table from '@/components/elements/Table'
import pagination from '@/components/layouts/pagination'
import Select from "@/components/elements/Select.vue";

const GET_ALL_BLOOD_RANGES = 'getBloodRanges'

export default {
  name: 'bloodRanges',
  components: { Input, Button, Table, Select, pagination },
  mixins: [ Pagination ],
  data: () => ({
    searchedText: '',
    page: 1,
    itemsPerPage: 15,
    loading: true,
  }),
  mounted () {
    this.loading = true
    this.appSettings()
  },
  methods: {
    ...mapMutations('bloodRanges', ['SET_DATA']),
    ...mapActions('bloodRanges', ['getBloodRanges']),
    ...mapActions(['appSettings']),

    inputSearch ($event) {
      this.searchedText = $event.toLowerCase()
    },

    refresh () {
      this.page = 1
      this.searchedText = ''
      this.getBloodRanges()
    },

    changeItemsPerPage(newRowsPerPage) {
      this.itemsPerPage = newRowsPerPage
      this.page = 1
    },

    changePagination(newPage) {
      this.page = newPage
    },
  },
  computed: {
    ...mapState('bloodRanges', {
      headers() {
        return [
          { text: 'Group', value: 'group', align: 'Left', sortable: false, width: 300 },
          { text: 'Marker', value: 'marker', align: 'Left', sortable: false, width: 200 },
          { text: 'Optimal Range', value: 'optimalRange', align: 'Left', sortable: false, width: 100 },
          { text: 'Lab Range', value: 'labRange', align: 'Left', sortable: false, width: 100 },
          { text: 'Units', value: 'units', align: 'Left', sortable: false, width: 80 },
        ];
      },

      bloodRangeItems (state) {
        const items = Object.assign([], state.bloodRanges?.data?.$values)
        return items
      },

      rowPerPageItems: () => ([5, 10, 15, 20]),

      bloodRangesListIsLoaded () {
        return this.$store.state.bloodRanges?.bloodRangesListIsLoaded
      }
    }),

    filteredItems() {
      const searchText = this.searchedText.toLowerCase()
      return this.bloodRangeItems.filter(item =>
        item.groupName.toLowerCase().includes(searchText) ||
        item.markerName.toLowerCase().includes(searchText)
      )
    },

    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage
      return this.filteredItems.slice(start, start + this.itemsPerPage)
    },

    pageCountCustom() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage)
    },

    getAllFunc: () => GET_ALL_BLOOD_RANGES,
  },
  beforeDestroy () {
    this.SET_DATA(['bloodRangesListIsLoaded', false])
  },
  watch: {
    bloodRangesListIsLoaded (val) {
      if (val) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blood-ranges {
  background-color: #F7FAFC;
  height: 100vh;
  padding: 16px;
  min-height: 100px;

  .loading {
    height: 100%;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(213, 213, 213, 0.5);
    border-radius: 16px;
  }

  &__total {
    font-size: 20px;
    font-weight: 600;
    span.num {
      color: $dark-green;
    }
  }

  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .blood-ranges-table {
    width: 100%;

    ::v-deep .v-card {
      height: auto!important;
    }

    ::v-deep .v-data-table-header th + th {
      border-left: 2px solid #F7FAFC !important;
    }

    ::v-deep th {
      height: 52px!important;
    }

    ::v-deep td {
      &:not(:first-child) {
        border-left: 2px solid #F7FAFC !important;
      }
      border-bottom: 1px solid #EDF2F7;
    }

    &__row {
      cursor: pointer;
    }

    &__row:hover {
      background-color: #008c8921!important;
    }

    &__row:last-child {
      td {
        border-bottom: 1px solid #EDF2F7;
      }
    }

    .font-bold {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.3;
      color: #2D3748;
    }

    .font-normal {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      color: #2D3748;
    }
  }

  .footer {
    height: 60px;

    .row-per-page {
      font-size: 14px;
      color: #718096;

      &__list {
        width: 60px;
      }

      ::v-deep .v-menu__content {
        min-width: 62px !important;

        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }

      ::v-deep .v-input {
        padding-top: 0px!important;
        margin-top: 0px!important;

        &__slot {
          margin: 0 !important;
        }

        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }

      ::v-deep .v-select {
        input {
          display: none;
        }

        &__selection {
          font-size: 14px;
          color: #718096;
        }

        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }

      .select {
        ::v-deep .v-input {
          &__control {
            .v-text-field__details {
              display: none!important;
            }
          }
        }
      }
    }

    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
}
</style>
